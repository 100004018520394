import { gql } from "@apollo/client"
import styled from "@emotion/styled"
import { Box, LinkButton, Text, theme } from "@pallet-hq/hegel"
import { VerticallyCenteredRow } from "components/atoms"
import { ReactComponent as PalletLogo } from "components/icons/logos/pallet/symbol.svg"
import config from "config"
import { PalletFooter_applylist } from "__generated__/PalletFooter_applylist"

const FooterFragment = gql`
    fragment PalletFooter_applylist on ApplylistType {
        id
        name
    }
`

type FooterProps = {
    applylist?: PalletFooter_applylist
}

const Footer = ({ applylist }: FooterProps) => {
    return (
        <FooterWrapper>
            <CenteredTextWrapper>
                <Text variant="paragraphMedium" color="gray600">
                    © 2023 Pallet Labs Inc. See{" "}
                    <StyledLink
                        href={`${config.MARKETING_SITE}/privacy-policy`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        privacy policy
                    </StyledLink>{" "}
                    and{" "}
                    <StyledLink
                        href={`${config.MARKETING_SITE}/terms-of-service`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        terms of service
                    </StyledLink>
                </Text>
                <LinkButton
                    href={config.MARKETING_SITE}
                    variant="secondary"
                    my="small"
                >
                    <VerticallyCenteredRow>
                        <PalletLogo width={24} height={24} />
                        <Text variant="uiLarge600" ml={1}>
                            Get started on Pallet
                        </Text>
                    </VerticallyCenteredRow>
                </LinkButton>
                <Text variant="paragraphMedium" color="gray600">
                    {applylist?.name || "This community"} is on{" "}
                    <StyledLink
                        href={config.MARKETING_SITE}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Pallet
                    </StyledLink>{" "}
                    — Where creators turn their community into recruiting
                    networks
                </Text>
            </CenteredTextWrapper>
        </FooterWrapper>
    )
}

Footer.fragment = FooterFragment
export default Footer

const FooterWrapper = styled.div`
    background: ${theme.colors.gray100};
    padding: ${theme.space.xlarge} ${theme.space.medium};
    margin: 0 auto;
`

const CenteredTextWrapper = styled(Box)`
    text-align: center;
`

const StyledLink = styled.a`
    color: inherit;
`
